<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="justify-content-center col-md-12">
      <CCard
        color="white"
        text-color="dark"
        class="text-center col-md-6"
        body-wrapper
      >
        <CAlert v-if="alert !== ''" color="danger">{{ alert }}</CAlert>
        <CAlert v-if="success !== ''" color="success">{{ success }}</CAlert>
        <img src="/logo.png" style="width:80%" alt="" />
        <CForm>
          <br />
          <CInput
            v-model="email"
            type="email"
            placeholder="Email Address..."
            autocomplete="username email"
          >
            <template #prepend-content>
              <CIcon name="cil-envelope-closed" />
            </template>
          </CInput>

          <CRow>
            <CCol col="12">
              <CButton
                @click="resetPasswordWithEmail"
                block
                color="dark"
                class="px-4"
              >
                ขอรหัสผ่านใหม่
              </CButton>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol col="12" class="text-center">
              <CButton block color="success" to="/login">
                เข้าสู่ระบบ
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCard>
    </CRow>
  </CContainer>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      alert: '',
      success: '',
    }
  },
  methods: {
    resetPasswordWithEmail() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.alert = ''
          this.success =
            'ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว กรุณาตรวจสอบอีเมลของท่าน'
          console.log('success')
        })
        .catch((error) => {
          this.alert = error.message
          this.success = ''
          console.log(error)
        })
    },
  },
}
</script>
